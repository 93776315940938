
import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
import {Component, Prop, Watch} from 'vue-property-decorator';
import {DateTime} from "luxon";
import PenaltySeries from "../../models/PenaltySeries";

@Component
export default class PenaltyChartComponent extends Vue {
  @Prop({required: true})
  public data!: PenaltySeries[];

  @Prop()
  private height!: number;

  @Prop({default: []})
  public xScaling!: any[];

  @Prop({required: true})

  @Prop({default: undefined})
  public title!: string;

  /**
   * initializes the chart
   * @private
   */
  public created() {
    Vue.component('apexchart', VueApexCharts);
  }

  @Watch('xScaling')
  private onXScalingChanged() {
    this.chartOptions = {
      ...this.chartOptions, ...{
        xaxis: {
          categories: this.xScaling
        }
      }
    };
  }

  /**
   * returns the series
   */
  get series(): any[] {
    const splicedData = this.data;

    // returns the spliced data with the name of the column
    return splicedData.map((penaltySeries: PenaltySeries) => {
      return {
        name: penaltySeries.type.name,
        data: penaltySeries.data.values
      };
    });
  }

  /**
   * chart options for the column chart
   */
  private chartOptions: any = {
    chart: {
      animations: {
        enabled: false
      },
      width: '100%',
      toolbar: {
        tools: {
          section: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          zoom: false
        },
        export: {
          csv: {
            filename: this.title,
          },
          svg: {
            filename: this.title,
          },
          png: {
            filename: this.title,
          }
        },
      },
    },
    colors: [
      this.$vuetify.theme.currentTheme['dark-green']?.toString(),
      this.$vuetify.theme.currentTheme['success']?.toString(),
      this.$vuetify.theme.currentTheme['primary']?.toString(),
      this.$vuetify.theme.currentTheme['orange']?.toString()
    ],
    legend: {
      position: 'top',
      fontSize: '15px',
      fontFamily: 'Signika-Light',
      color: this.$vuetify.theme.currentTheme['dark-green']?.toString(),
      horizontalAlign: 'left',
      itemMargin: {
        horizontal: 10
      },
      markers: {
        size: 0
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '60%'
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    title: {
      text: this.title,
      style: {
        fontSize: "20px",
        color: this.$vuetify.theme.currentTheme['light-on-background']?.toString(),
        fontFamily: 'Signika-Regular',
        fontWeight: 400
      }
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      x: {
        show: true,
        formatter: this.getDateByIndex
      },
      y: {
        show: true,
        formatter: this.translateToCurrency
      }
    },
    xaxis: {
      type: 'datetime',
      categories: this.xScaling,
      labels: {
        style: {
          colors: this.$vuetify.theme.currentTheme['closeButton']?.toString(),
          fontSize: '12px',
          fontFamily: 'Signika-Reguar'
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: this.$vuetify.theme.currentTheme['closeButton']?.toString(),
          fontSize: '12px',
          fontFamily: 'Signika-Regular'
        },
      },
    },
    grid: {
      show: true,
      borderColor: this.$vuetify.theme.currentTheme['table-border'],
      padding: {
        top: 15,
        bottom: 20,
        left: 10
      },
    },
  };

  /**
   * returns the formatted date by the milliseconds
   * @param milliseconds
   * @private
   */
  private getDateByIndex(milliseconds: number) {
    return DateTime.fromMillis(milliseconds).toFormat('DD');
  }

  /**
   * translates the passed number to a currency sum
   * @param value
   * @private
   */
  private translateToCurrency(value: number) {
    return this.$formatCurrency(value!);
  }
}
